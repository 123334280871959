import { ref } from 'vue';

const tokenKey = "user-token";
export const isLogin = ref(!!localStorage.getItem(tokenKey));
export const getToken = () => localStorage.getItem(tokenKey);
export const getEmail = () => localStorage.getItem("email") || "none";

export const setEmail = (value) => {
  localStorage.setItem("email", value);
};

export const setUsername = (value) => {
  localStorage.setItem("name", value);
};

export const setToken = (token) => {
  localStorage.setItem(tokenKey, token);
  isLogin.value = true;
};

export const clearToken = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem("email");
  localStorage.removeItem("name");
  localStorage.removeItem("user-token")
};
