<template>
  <button
  class="boolv-btn"
  @click="handleClick($event)"
  :style="style"
  :class="[
    `boolv-btn-${type}`,
    `boolv-btn-${size}`,
    isPress ? 'btn-press' : '',
    disabled ? 'disabled' : ''
  ]"
  >
    <svg v-if="loading" class="boolv-btn-icon loading-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_298_450)">
        <path d="M4.66595 2.22689C1.47733 4.06784 0.38483 8.1451 2.22578 11.3337C4.06673 14.5223 8.144 15.6148 11.3326 13.7739C14.5212 11.9329 15.6137 7.85568 13.7728 4.66706" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_298_450">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <v-render v-else :vnode="icon"/>
    <slot></slot>
  </button>
</template>

<script setup>
import { ref } from 'vue';

const isPress = ref(false);
const emits = defineEmits(['click']);
const props = defineProps({
  // primary outlined text shadow
  type: {
    type: String,
    default: 'primary'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  // L M S XS
  size: {
    type: String,
    default: 'XS'
  },
  icon: {
    type: Object,
    default: null,
  },
  style: {
    type: CSSStyleDeclaration,
    default: null,
  }
});


const size = props.size.toLowerCase();

function handleClick(e) {
  emits('click', e);
}
</script>

<style scoped>
.boolv-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  padding: 7px 36px;
  border-radius: 9999px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  box-sizing: content-box;
  text-align: center;
}

.boolv-btn-primary, .boolv-btn-shadow {
  color: #fff;
  background-image: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);
}

.boolv-btn-primary:hover, .boolv-btn-shadow:hover {
  background-image: linear-gradient(289deg, #6F46F4 5.38%, #957AEC 99.95%);
}

.boolv-btn-primary.disabled, .boolv-btn-shadow.disabled {
  background-image: none;
  background-color: #BBBFC4;
}

.boolv-btn-s {
  padding-block: 10px;
}

.boolv-btn-m {
  padding-block: 15px;
  font-size: 16px;
  line-height: 24px;
}

.boolv-btn-l {
  padding-block: 20px;
  font-size: 16px;
  line-height: 24px;
}

.boolv-btn-outlined {
  border: 1px solid #BBBFC4;
  color: #060606;
  background-color: #fff;
}

.boolv-btn-outlined:hover {
  background-color: #F5F6F7;
}

.boolv-btn-text {
  min-width: fit-content;
  padding: 0;
  border-radius: 0;
  color: #8F959E;
}

.boolv-btn-text.boolv-btn-l {
  font-size: 16px;
  line-height: 24px;
}

.boolv-btn-text:hover {
  color: #A378FF;
}

.boolv-btn-text.disabled {
  color: #BBBFC4;
}

.boolv-btn-shadow {
  box-shadow: 0px 6px 18px rgba(138, 98, 255, 0.43);
}

.boolv-btn-shadow:hover {
  box-shadow: 0px 6px 18px rgba(138, 98, 255, 0.43);
}

.boolv-btn-shadow.disabled {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
}

.loading-icon {
  color: #fff;
  line-height: 1;
  animation: rotate .8s linear infinite;
}

.boolv-btn-outlined > .loading-icon, .boolv-btn-text > .loading-icon {
  color: #875EFF;
}

.boolv-btn-icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>