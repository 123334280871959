import { _get, _post, _put } from "@/utils/request.js";
const preApi =
  process.env.NODE_ENV !== "production" ? "/api" : process.env.VUE_APP_API;

const URL = {
  login: preApi + "/user/login", //登录
  appSumoRegister: preApi + '/user/register/appsumo', //appsumo登录
  appSumoLogin: preApi + '/user/login/appsumo',
  logout: preApi + "/user/logout", // 退出登录
  profile: preApi + "/user/current", //获取用户信息
  sendCode: preApi + "/email/send/registerCode", //发送验证码
  register: preApi + "/user/register/emailCode", //注册
  help: preApi + "/user/password/help", //重置密码 发送邮件
  resetPwd: preApi + "/user/password", //设置新的密码
  userSubscription: preApi + "/quota", // 获取用户权益信息
  subscribeState: preApi + "/subscribe-state", //查看订阅状态
  userInfoByAccessToken: preApi + "/google/login/userInfoByAccessToken", //google登录
  checkResource: preApi + "/const_resource/check",
  deductResource: preApi + "/const_resource/v3/deduct",
  getNotification: preApi + "/message/page", // 获取消息中心列表
};

const getUserProfile = async () => _get(URL.profile);

const login = async (data) => _post(URL.login, data);

const appSumoLogin = async (data) => _post(URL.appSumoLogin, data);

const appSumoRegister = async (data) => _post(URL.appSumoRegister, data);

const logout = async () => _put(URL.logout);

const register = async (data) => _post(URL.register, data);

const sendCode = async (data) => _post(URL.sendCode, data);

const help = async (data) => _put(URL.help, data);

const resetPwd = async (data) => _put(URL.resetPwd, data);
// 获取用户权益信息
const getUserSubscription = async (data) => _get(URL.userSubscription, data);
//查看订阅状态
const getSubscribeState = async (data) => _get(URL.subscribeState, data);

const getUserInfoByAccessToken = async (data) =>
  _get(URL.userInfoByAccessToken, data);

const checkResource = async (data) => _post(URL.checkResource, data);

const deductResource = async (data) => _post(URL.deductResource, data);

const getNotification = async (data) => _get(URL.getNotification, data);

export {
  getUserProfile,
  logout,
  login,
  appSumoLogin,
  appSumoRegister,
  sendCode,
  help,
  register,
  resetPwd,
  getUserSubscription,
  getSubscribeState,
  getUserInfoByAccessToken,
  checkResource,
  deductResource,
  getNotification,
};
