import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { authenticator } from "@/utils";
import { isLogin } from "@/utils/auth.js";
import { deepMergeObject } from "@/utils/util.js";
import { useViewStore } from "@/store";

/** @type {import('vue-router').RouteRecordRaw[]} */
const constantRouterMap = [
  {
    path: "/",
    name: "home",
    component: () => import("@/layouts/index.vue"),
    meta: {
      showHeader: "home",
      view: { minViewWidth: '390px' },
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/homePage/index.vue"),
      },
      {
        path: "/appsumoLogin",
        name: "appsumoLogin",
        component: () => import("@/views/appsumoLogin/index.vue"),
        meta: {
          showHeader: "",
          view: {
            header: { disabled: true },
          },
        },
      },
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/index.vue"),
        meta: {
          showHeader: "",
          view: {
            header: { disabled: true },
          },
        },
      },
      {
        path: "/signup",
        name: "signup",
        component: () => import("@/views/login/index.vue"),
        meta: {
          showHeader: "",
          view: {
            header: { disabled: true },
          },
        },
      },
      {
        path: "/resetPwd",
        name: "resetPwd",
        component: () => import("@/views/login/forgot-password/index.vue"),
        meta: {
          view: {
            header: { theme: 'dark' }
          }
        }
      },
      {
        path: "/terms",
        name: "termsOfService",
        component: () => import("@/views/policy/termsOfService.vue"),
        meta: {
          view: {
            minViewWidth: '720px',
            header: { theme: 'dark' }
          }
        },
      },
      {
        path: "/privacy",
        name: "privacyPolicy",
        component: () => import("@/views/policy/privacyPolicy.vue"),
        meta: {
          view: {
            minViewWidth: '720px',
            header: { theme: 'dark' }
          }
        },
      },
      {
        path: "/cookies",
        name: "cookiesPolicy",
        component: () => import("@/views/policy/cookiesPolicy.vue"),
        meta: {
          view: {
            minViewWidth: '720px',
            header: { theme: 'dark' }
          }
        },
      },
      {
        path: "/premium",
        name: "premium",
        component: () => import("@/views/premium/index.vue"),
        meta: { showHeader: "tools", requiresAuth: true },
      },
      {
        path: "/homePremium",
        name: "homePremium",
        component: () => import("@/views/homePremium/index.vue"),
      },
      {
        path: "/refund",
        name: "refund",
        component: () => import("@/views/premium/refund.vue"),
        meta: { showHeader: "tools", requiresAuth: true },
      },
      {
        path: "/solution/:name",
        name: "solution",
        component: () => import("@/views/solution/index.vue"),
      },
    ],
    redirect: () => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      return { path: "/home" };
    },
  },
  {
    path: "/tools",
    name: "tools",
    meta: {
      requiresAuth: true,
      showHeader: "tools",
      isAuthentication: true,
      view: { minViewWidth: '1400px' },
    },
    component: () => import("@/layouts/index.vue"),
    children: [
      // {
      //   path: "/freePuzzle",
      //   name: "freePuzzle",
      //   component: () => import("@/views/pintu/freePuzzle.vue"),
      // },
      {
        path: "/background-remover",
        name: "backgroundRemove",
        describe: "人像抠图",
        component: () => import("@/views/models/background-remove/index.vue"),
      },
      {
        path: "/object-background-remover",
        name: "objectBackgroundRemove",
        describe: "物体抠图",
        component: () => import("@/views/models/object-background-remover/index.vue"),
      },
      {
        path: "/background-blur",
        name: "backgroundBlur",
        describe: "背景模糊",
        component: () => import("@/views/models/ambiguous-background/index.vue"),
      },
      {
        path: "/facial-movement",
        name: "facialMovement",
        describe: "3d脸动",
        component: () => import("@/views/models/3d-effect/index.vue"),
      },
      {
        path: "/video-background-remove",
        name: "video-background-remove",
        describe: "视频抠图",
        component: () => import("@/views/models/video-background-remove/index.vue"),
      },

      {
        path: "/photo-storytelling",
        name: "photoStorytelling",
        describe: "看图说垃圾话",
        component: () => import("@/views/models/photo-story-teller/index.vue"),
      },
      {
        path: "/sugar-comments",
        name: "sugarComments",
        describe: "夸夸",
        component: () => import("@/views/models/kua-kua/index.vue"),
      },

      {
        path: "/AI-paraphraser",
        name: "AIparaphraser",
        describe: "文本重写",
        component: () => import("@/views/models/tex-revisor/index.vue"),
      },
      {
        path: "/removeClothBackground",
        name: "removeClothBackground",
        describe: "衣服抠图",
        component: () => import("@/views/models/removeClothBackground.vue"),
      },
      {
        path: "/object-eraser",
        name: "objectEraser",
        component: () => import("@/views/models/object-eraser/index.vue"),
      },
      {
        path: "/image-enhancer",
        name: "imageEnhancer",
        component: () => import("@/views/models/image-enhancer/index.vue"),
      },
      {
        path: "/image-filter",
        name: "image-filter",
        component: () => import("@/views/models/image-filter/index.vue"),
      },
      {
        path: "/animation",
        name: "dynamicEffects",
        component: () => import("@/views/models/imageDynamicEffects.vue"),
      },
      {
        path: "/image-resizer",
        name: "imageResizer",
        component: () => import("@/views/models/image-resizer/index.vue"),
      },
      {
        path: "/image-compressor",
        name: "imageCompressor",
        component: () =>
          import("@/views/models/image-compressor/imageCompressor.vue"),
      },
      {
        path: "/effects",
        name: "effects",
        component: () => import("@/views/models/animation-effects/index.vue"),
      },


      // {
      //   path: "/face-replacement",
      //   name: "faceReplacement",
      //   component: () => import("@/views/models/face-replacement/index.vue"),
      // },
      {
        path: "/video-enhancer",
        name: "videoEnhancer",
        component: () => import("@/views/models/video-enhancer/index.vue"),
      },
    ],
    redirect: () => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      return { path: "/background-remover" };
    },
  },
  // 将匹配所有内容并将其放在 `$route.params.pathMatch` 下
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/layouts/index.vue"),
    redirect: () => {
      return { path: "/" };
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: constantRouterMap,
});

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !isLogin.value) {
    // 此路由需要授权，请检查是否已登录
    // 如果没有，则重定向到登录页面
    return {
      path: "/login",
      // 保存我们所在的位置，以便以后再来
      query: { redirect: to.fullPath },
    };
  }

  if (!to.path.startsWith('/login')) {
    authenticator.refresh();
  }
});

router.afterEach((to) => {
  const viewStore = useViewStore();
  const matchedViewMeta = to.matched.filter(item => 'view' in item.meta).map(item => item.meta.view);
  
  window.scrollTo(0, 0);
  viewStore.changeState(deepMergeObject({}, ...matchedViewMeta));
});
export default router;
