import { modalManager } from '@/components/boolv-ui/global-modal/reactive.js';
import { useSubscriptionInfo } from '@/store/user.js';

class Authenticator {
  constructor() {
    this.store = null;
    this._hooks = {
      loaded: [],
      refreshed: [],
    }
  }

  /** 绑定事件 */
  on (event, callback) {
    if (this.store !== null) {
      if (event === 'loaded') {
        callback(this);
      }
    } else {
      this._hooks[event]?.push(callback);
    }
  }

  /** 解绑事件 */
  off (event, callback) {
    if (event in this._hooks) {
      const index = this._hooks[event].indexOf(callback);

      if (index !== -1) {
        this._hooks[event].splice(index, 1);
      }
    }
  }

  /** 刷新权限 */
  async refresh () {
    if (this.store === null) {
      await loadSubscriptionInfoStore();
      return;
    }

    await new Promise((resolve) => {
      this.on('loaded', resolve);
    });

    await this.store.refresh();

    if (this.store !== null) {
      this._hooks.refreshed.forEach((callback) => {
        callback(authenticator);
      });
    }
  }

  /** 检查权限 */
  async authenticate (permission) {
    if (permission === void 0 || permission === '') {
      return true;
    }

    await new Promise((resolve) => {
      this.on('loaded', resolve);
    });

    const resource = this.store.userResourceList.find(item => item.resourceCode.toUpperCase() === permission);

    if (resource === void 0) {
      return this.store.permissionList.includes(permission);
    } else {
      return resource.unlimitedNum || resource.num > 0;
    }
  }

  /** 获取权限类型 (`basic`, `custom`, `unknow`) */
  async getPermissionType (permission) {
    if (permission === void 0 || permission === '') {
      return 'basic';
    }

    await new Promise((resolve) => {
      this.once('loaded', resolve);
    });

    const index = this.store.userResourceList.findIndex(item => item.resourceCode.toUpperCase() === permission);

    if (index === -1) {
      return this.store.permissionList.includes(permission) ? 'basic' : 'unknow';
    } else {
      return 'resource';
    }
  }
}

export const authenticator = new Authenticator();

async function loadSubscriptionInfoStore () {
  const store = useSubscriptionInfo();

  store.refresh().finally(() => {
    authenticator.store = store;

    authenticator._hooks.loaded.forEach((callback) => {
      callback(authenticator);
    });

    authenticator._hooks.loaded = [];
  });
}


export const directive = function (app) {
  // 鉴权失败时的默认处理函数
  const defaultHandler = e => {
    modalManager.applyTemplate('no_premission');

    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  // 初始化指令值
  const initValue = (value) => {
    if (value === undefined) {
      return {
        type: 'basic',
        ok: null,
        no: defaultHandler,
      }
    }

    if (typeof value !== 'object') {
      throw new TypeError('The directive value must be a object');
    }

    const _value = {
      type: 'type' in value ? value.type : 'basic',
      ok: 'ok' in value ? value.ok : null,
      no: 'no' in value ? value.no : defaultHandler,
    };

    if (
      typeof _value.type !== 'string' ||
      typeof _value.no !== 'function' ||
      (_value.ok !== null && typeof _value.ok !== 'function')
    ) {
      throw new TypeError('The directive value is incorrect.');
    }

    return _value;
  }


  // 指令各阶段的处理函数
  return {
    created (dom, { arg, value }) {
      const _value = initValue(value);

      authenticator.authenticate(arg).then(result => {
        if (result) {
          if (_value.ok !== null) {
            dom.addEventListener('click', _value.ok, true);
            dom.addEventListener('drop', _value.ok, true);
          }
        } else {
          dom.addEventListener('click', _value.no, true);
          dom.addEventListener('drop', _value.no, true);
        }
      });
    },

    updated (dom, { arg, value, oldValue }) {
      if (value === oldValue) {
        return;
      }

      const _value = initValue(value);
      const _oldValue = initValue(oldValue);

      authenticator.authenticate(arg).then(result => {
        if (result) {
          if (_oldValue.ok !== null) {
            dom.removeEventListener('click', _oldValue.ok, true);
            dom.removeEventListener('drop', _oldValue.ok, true);
          }

          if (_value.ok !== null) {
            dom.addEventListener('click', _value.ok, true);
            dom.addEventListener('drop', _value.ok, true);
          }
        } else {
          if (_oldValue.no !== _value.no) {
            dom.removeEventListener('click', _oldValue.no, true);
            dom.removeEventListener('drop', _oldValue.no, true);
            dom.addEventListener('click', _value.no, true);
            dom.addEventListener('drop', _value.no, true);
          }
        }
      });
    }
  }
};
