import lodash from 'lodash';
import { directive as authenticate } from '@/utils/authenticate';

const directives = {
  authenticate,
};

export default {
  install(app) {
    Object.keys(directives).forEach((key) => {
      if (lodash.isFunction(directives[key])) {
        app.directive(key, directives[key](app));
      } else {
        app.directive(key, directives[key]);
      }
    });
  }
};
