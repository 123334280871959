import { defineStore } from "pinia";
import { getBackgroundLib } from '@/api/models/remove-background.js'
export const useBackgroundDRemoveStore = defineStore("backgrounDRemove", {
  // other options...
  state: () => {
    return {
      getBackgroundLoading: false,
      backgroundList: [],
      currentPager: ''

    };
  },
  actions: {
    async getBackgroundList (params) {
      this.getBackgroundLoading = true
      getBackgroundLib(params)
        .then(res => {
          if (res.code === 0) {
            const { outputUrls } = res.data
            this.backgroundList = outputUrls
            this.getBackgroundLoading = false;
            this.currentPager = res.data.pager
            console.log('backgroundList', this.backgroundList)
          }
        })
        .catch(err => {
          console.log('err====', err)
        })
    },
  },
});
