<template>
  <div v-show="modal.isShow" class="global-modal-container">
    <div v-if="modal.customModal === null" id="globalModal">
      <div class="modal-content-container">
        <v-render :vnode="modal.icon" style="margin-right: 18px"/>
        <div>
          <p class="global-modal-title">
            <v-render :vnode="modal.title"/>
            <img v-if="modal.showClose" :src="close_icon" width="24" height="24" @click="modal.handleClose"/>
          </p>
          <div class="global-modal-content">
            <v-render :vnode="modal.content"/>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <v-render :vnode="modal.footer"/>
      </div>
    </div>
    <v-render v-else :vnode="modal.customModal"/>
  </div>
</template>

<script setup>
import { watch, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';
import modal from './reactive';
import close_icon from '@/assets/close_icon.svg';

const instance = getCurrentInstance();

watch(() => modal.isShow, val => {
  if (val) {
    document.body.setAttribute(instance.subTree.scopeId, '');
  } else {
    document.body.removeAttribute(instance.subTree.scopeId);
  }
});

onMounted(() => {
  if (modal.isShow) {
    document.body.setAttribute(instance.subTree.scopeId, '');
  }
})

onBeforeUnmount(() => {
  document.body.removeAttribute(instance.subTree.scopeId)
});
</script>

<style scoped>
body {
  overflow: hidden;
}

.global-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

#globalModal {
  width: 420px;
  padding: 24px;
  background-color: white;
  text-align: left;
  border-radius: 4px;
}

.modal-content-container {
  display: flex;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.global-modal-title {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #1F2329;
  margin-bottom: 8px;
}

.global-modal-title > img {
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
}

.global-modal-title > img:hover {
  background-color: #eaeaea;
}

.global-modal-content {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 22px;
  color: #1F2329;
}
</style>
