import { defineStore } from "pinia";

// useStore 可以是 useUser、useCart 之类的任何东西
// 第一个参数是应用程序中 store 的唯一 id
export const useSideStore = defineStore("side", {
  // other options...
  state: () => {
    return {
      isCollapse: true,
      showGlobalMask: false,
      minClient: null,
      clientHeight: 0,
    };
  },

  actions: {
    handleShowGlobalMask (isShow) {
      this.showGlobalMask = isShow
    },
    setCurrentClient (isMinClient) {
      this.minClient = isMinClient
    },
    setCurrentClientHeight (height) {
      this.clientHeight = height
    }
  }
});
