import { defineStore } from "pinia";
import {
  login as userLogin,
  logout as userLogout,
  appSumoLogin,
  appSumoRegister,
  getUserProfile,
  sendCode,
  help,
  register,
  resetPwd,
  getUserSubscription,
  getSubscribeState,
  getUserInfoByAccessToken,
  checkResource,
  deductResource,
} from "@/api/userInfo/index";
import { setToken, clearToken, setEmail, setUsername } from "@/utils/auth";
import { eventTracking } from "@/utils/eventTracking.js";
import { usePremiumStore } from "@/store/premium.js";
import { isLogin } from "@/utils/auth.js";
import { createDynamicRef } from "@/utils/util.js";

function initSubscriptionInfo () {
  return {
    dueTime: Infinity,
    packageCode: 201001,
    paymentPlatform: null,
    permissionList: [],
    userResourceList: [
      {
        dueTime: Infinity,
        num: 1,
        resourceCode: 'booltool_free_permission',
        unlimitedDuration: false,
        unlimitedNum: false,
      }
    ]
  };
}

export const useSubscriptionInfo = defineStore("subscriptionInfo", {
  state: function () {
    return initSubscriptionInfo();
  },
  actions: {
    async refresh () {
      if (!isLogin.value) {
        return this.init();
      } else {
        const { data = this.init() } = await getUserSubscription();
        this.$patch(data);
        this.updateIntercomPackageCode();
        return data;
      }
    },

    updateIntercomPackageCode() {
      if (window.Intercom) {
        window.Intercom('update', { package_code: this.packageCode })
      }
    },

    init () {
      const info = initSubscriptionInfo();
      this.$patch(info);
      return info;
    },

    getUserResource (resourceCode) {
      return createDynamicRef(() => this.userResourceList.find(item => item.resourceCode.toUpperCase() === resourceCode) || {});
    }
  }
});

export const useUserStore = defineStore("user", {
  // other options...
  state: () => {
    return {
      token: "",
      userName: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
      hasCredit: null,
      loginMsg: "",
      remainCount: 0,
      expireTime: null,
      isSubscribed: false,
      showNotification: false, //打开消息中心，同时也会更新消息列表
      updateNotification: 0,   //其他组件通知更新消息列表的标志
    };
  },
  getters: {},
  actions: {
    // 获取用户信息
    async info () {
      const result = await getUserProfile();
      this.setInfo(result);
    },
    //获取用户权益
    async userSubscription () {
      // if (isLogin.value) {
      //   try {
      //     const result = await getUserSubscription();
      //     const { code } = result;
      //     if (code === 0) {
      //       const { data } = result;
      //       this.remainCount = data.reduce((pre, cur) => {
      //         if (!cur.indefinitely) {
      //           this.isSubscribed = true;
      //         }
      //         if (cur.remainCount) {
      //           return pre + cur.remainCount;
      //         }
      //         return pre;
      //       }, 0);
      //       this.expireTime = data.reduce((pre, cur) => {
      //         if (cur.expireTime) {
      //           return Math.max(pre, cur.expireTime);
      //         }
      //         return pre;
      //       }, 0);
      //       console.log(
      //         "this.remainCount,this.expireTime-------->",
      //         this.remainCount,
      //         this.expireTime
      //       );
      //       console.log("this.isSubscribed--->", this.isSubscribed);
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
    },
    //获取用户订阅套餐
    /**
     * @deprecated 需从useSubscriptionInfo中获取相关数据或直接使用请求接口
     */
    async subscribeState () {
      const subscriptionInfo = useSubscriptionInfo();
      await subscriptionInfo.refresh();

      // if (isLogin.value) {
      //   try {
      //     const result = await getSubscribeState();
      //     const { code } = result;

      //     if (code === 0) {
      //       // 免费的free套餐也是订阅？？？
      //       if (result.data) {
      //         sessionStorage.setItem('isSubscribed', true);
      //       } else {
      //         sessionStorage.setItem('isSubscribed', false);
      //       }
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
    },

    updateIntercomUserInfo() {
      if (window.Intercom) {
        window.Intercom('update', {
          user_id: localStorage.getItem("email"),
          email: localStorage.getItem("email"),
        })
      }
    },
    // 设置用户的信息
    setInfo (partial) {
      this.$patch(partial);
      this.updateIntercomUserInfo();
    },
    // 重置用户信息
    resetInfo () {
      this.$reset();
    },
    // 异步登录并存储token
    async userLogin (loginForm) {
      try {
        const result = await userLogin(loginForm);
        if (result.code === 0) {
          const token = result.data.token;
          if (token) {
            setEmail(result.data.email);
            setUsername(result.data.username);
            setToken(token);
            this.userName = result.data.username;
            this.setInfo(result.data);
          }
        }
        return result;
      } catch (error) {
        this.loginMsg = error.massage;
        return error;
      }
    },

    async appSumoLogin (loginForm) {
      try {
        const result = await appSumoLogin(loginForm);
        if (result.code === 0) {
          const token = result.data.token;
          if (token) {
            setEmail(result.data.email);
            setUsername(result.data.username);
            setToken(token);
            this.userName = result.data.username;
            this.setInfo(result.data);
          }
        }
        return result;
      } catch (error) {
        this.loginMsg = error.massage;
        return error;
      }
    },

    async appSumoRegister (registerForm) {
      try {
        const result = await appSumoRegister(registerForm);
        if (result.code === 0) {
          const token = result.data.token;
          if (token) {
            setEmail(result.data.email);
            setUsername(result.data.username);
            setToken(token);
            this.userName = result.data.username;
            this.setInfo(result.data);
          }
        }
        return result;
      } catch (error) {
        this.loginMsg = error.massage;
        return error;
      }
    },


    // google登录并存储token
    async googleLogin (accessToken) {
      try {
        const result = await getUserInfoByAccessToken(accessToken);
        if (result.code === 0) {
          console.log(result);
          const token = result.data.token;
          if (token) {
            eventTracking("booltool_signup_success");
            setToken(token);
            setEmail(result.data.email);
            setUsername(result.data.username);
            this.userName = result.data.username;
            this.email = result.data.email;
            this.setInfo(result.data);
          }
        }
        return result;
      } catch (error) {
        return error;
      }
    },

    // Logout
    async logout () {
      const res = await userLogout();
      //退出登录后
      this.resetInfo();
      clearToken();
      return res;
    },

    //发送验证码
    async sendEmail (email) {
      try {
        const result = await sendCode(email);
        return result;
      } catch (error) {
        return error;
      }
    },

    //注册
    async register (params) {
      try {
        const result = await register(params);
        if (result.code === 0) {
          const token = result.data.token;
          if (token) {
            eventTracking("booltool_signup_success");
            setToken(token);
            setEmail(result.data.email);
            setUsername(result.data.username);
            this.userName = result.data.username;
            this.setInfo(result);
          }
        }
        return result;
      } catch (error) {
        return error;
      }
    },
    //重置密码，发送邮件
    async help (params) {
      const result = await help(params);
      return result;
    },
    //更改密码
    async resetPwd (params) {
      await resetPwd(params);
    },
    // 检查是否有credit
    async checkResource (params) {
      checkResource(params)
        .then((res) => {
          const { code } = res;
          if (code === 0) {
            this.hasCredit = true;
          } else {
            this.hasCredit = false;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 资源扣费
    async deductResource (params) {
      await deductResource(params);
    },
  },
});
