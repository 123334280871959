import { _post, fileUpload } from "@/utils/request.js";

const TMPURL =
  process.env.NODE_ENV === "development"
    ? "/uploadData"
    : process.env.VUE_APP_AWS;
//埋点上报
export function buryPointReport (params) {
  return _post(TMPURL, params);
}
//文件上传前置
export function fileUploadPre (params) {
  return _post(TMPURL + "/upload", params);
}

//文件上传
export function uploadFile (url, params, noNeedAuthorization) {
  return fileUpload(url, params, noNeedAuthorization);
}

