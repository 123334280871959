"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var App_vue_1 = require("./App.vue");
var vue_meta_1 = require("vue-meta");
var ant_design_vue_1 = require("ant-design-vue");
require("ant-design-vue/dist/antd.less");
var router_1 = require("./router");
var vue_drag_drop_1 = require("vue-drag-drop");
var pinia_1 = require("pinia");
var v_clipboard_1 = require("v-clipboard");
require("./assets/styles/index.less");
var ant_design_vue_2 = require("ant-design-vue");
var directives_1 = require("./utils/directives");
var advanced_video_vue_1 = require("@/components/advanced-video.vue");
var index_vue_1 = require("@/components/boolv-ui/boolv-button/index.vue");
require("./components/boolv-ui/global-modal/defaultModalTemplate");
var vue3_google_signin_1 = require("vue3-google-signin");
ant_design_vue_2.message.config({
    top: "64px",
    duration: 3,
    maxCount: 1,
});
var VRender = function (_a) {
    var vnode = _a.vnode;
    return vnode;
};
VRender.props = ['vnode'];
vue_1.createApp(App_vue_1.default)
    .use(vue3_google_signin_1.default, {
    clientId: '288310485655-g703ltcdvd9i75uk5b0n3uhumc42ld3o.apps.googleusercontent.com',
})
    .use(ant_design_vue_1.default)
    .use(router_1.default)
    .use(vue_drag_drop_1.default)
    .use(pinia_1.createPinia())
    .use(vue_meta_1.createMetaManager())
    .use(v_clipboard_1.default)
    .use(directives_1.default)
    .component('v-render', VRender)
    .component('advanced-video', advanced_video_vue_1.default)
    .component('boolv-btn', index_vue_1.default)
    .mount('#app');
