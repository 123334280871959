import { Modal } from "ant-design-vue";
import icon_close from "@/assets/icon_close.svg";
import iconWarning from "@/assets/common-icons/icon_warning_colorful.svg";
import router from "@/router/index";
import "./index.less";

import { setup, h } from "vue";

const defaultConfig = {
  title: "Insufficient credit",
  content:
    "Looks like you don't have enough credits to use this service. Upgrade to get more credits. ",
  closable: true,
  class: "nocredit-modal-class",
  okText: "Upgrade now",
  centered: true,
  width: "450px",
  icon: <img src={iconWarning} class="container-warn-icon" />,
  closeIcon: (
    <div class="flex items-center justify-center transition container-close-icon">
      <img src={icon_close} class="close-icon" />
    </div>
  ),
};

const showNoCreditModal = (config) => {
  // Modal.warning({
  //   ...defaultConfig,
  //   ...config,
  //   onOk() {
  //     router.push("/premium");
  //   },
  //   onCancel () {
  //     config.onCancel && config.onCancel()
  //   }
  // });
};

export default showNoCreditModal;
