<template>
  <video
    ref="videoRef"
    :preload="props.lazy ? props.preload === 'none' ? 'none' : 'metadata' : props.preload"
    :autoplay="props.lazy ? false : props.autoplay"
    :muted="props.muted"
    :playsinline="props.playsinline"
    :src="props.src"
    :poster="props.poster"
    @videofocus="handleVideoFocus"
    @videoblur="handleVideoBlur"
  >
    <slot></slot>
  </video>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick, defineExpose } from 'vue';

const videoRef = ref(null);
const { sources, preventDefault, ...originalProps } = defineProps({
  src: {
    type: String,
    default: '',
  },
  // 是否懒加载
  lazy: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: String,
    default: 'metadata',
  },
  playsinline: {
    type: String,
    default: 'inline',
  },
  muted: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  // 是否重新加载 (当元素重新进入视窗时)
  reload: {
    type: Boolean,
    default: false,
  },
  // 是否重新开始 (当元素重新进入视窗时)
  restart: {
    type: Boolean,
    default: false,
  },
  poster: {
    type: String,
    default: '',
  },
  preventDefault: {
    type: Boolean,
    default: false,
  },
  sources: {
    type: Array,
    default: [],
  }
});

const propsList = sources.map(({media='', ...replacedProps}) => {
  const props = {
    ...originalProps,
    ...replacedProps,
  };

  return [media, props];
});

const props = ref(matchQuery());

function matchQuery() {
  for (const [media, props] of propsList) {
    if (window.matchMedia(media).matches) {
      return props;
    }
  }

  return originalProps;
}

function changeSrc(
  src,
  paused=videoRef.value.paused,
  currentTime=videoRef.value.currentTime
) {
  videoRef.value.src = src;

  if (HTMLMediaElement.HAVE_CURRENT_DATA <= videoRef.value.readyState) {
    paused ? videoRef.value.pause() : videoRef.value.play();
    videoRef.value.currentTime = currentTime;
  } else {
    videoRef.value.oncanplay = () => {
      paused ? videoRef.value.pause() : videoRef.value.play();
      videoRef.value.currentTime = currentTime;
      videoRef.value.oncanplay = null;
    };
  }
}

function handleResize() {
  const _props = matchQuery();
  const paused = videoRef.value.paused;

  if (_props.src === props.value.src) {
    return;
  }
  
  props.value = _props;

  nextTick(() => {
    changeSrc(_props.src, paused, 0);
  });
}

// 编写一个判断元素是否在视窗中的函数
function isInScreen(target) {
  const clientRect = target.getBoundingClientRect();
  return clientRect.top <= innerHeight && clientRect.bottom >= 0;
}

let inScreen = false;
function handleEventListener() {
  if (isInScreen(videoRef.value)) {
    if (!inScreen) {
      inScreen = true;
      videoRef.value.dispatchEvent(new Event('videofocus'));
    }
  } else {
    if (inScreen) {
      inScreen = false;
      videoRef.value.dispatchEvent(new Event('videoblur'));
    }
  }
}

let canplay = false;
function handleVideoFocus() {
  if (props.value.autoplay) {
    canplay = true;
  }

  if (props.value.reload) {
    videoRef.value.load();
  } else if (props.value.restart) {
    videoRef.value.currentTime = 0;
  }
}

function handleVideoBlur() {
  canplay = false;
}

let timer = null;
function handleScroll() {
  const temp = canplay;

  handleEventListener();
  
  if (temp === canplay) {
    return;
  }
  
  if (canplay) {
    videoRef.value.play();
  } else {
    videoRef.value.pause();
  }
}

onMounted(() => {
  scrollY === 0 && handleScroll();

  window.addEventListener('resize', handleResize);
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  // clearTimeout(timer);
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('scroll', handleScroll);
});

defineExpose({
  videoRef,
  props,
})
</script>
