import { _post } from "@/utils/request.js";
const preApi = process.env.NODE_ENV !== "production" ? "/api" : process.env.VUE_APP_API;
const URL = {
  matchBackgroundLib: preApi + "/ai/match_background_lib", //登录

};

const getBackgroundLib = async (data) => _post(URL.matchBackgroundLib, data);


export { getBackgroundLib };
