import { defineStore } from "pinia";

const saveImagesFileList = sessionStorage.getItem('saveImagesFileList') && JSON.parse(sessionStorage.getItem('saveImagesFileList'))
export const useUploadImagesStore = defineStore('uploadImages', {
  // other options...
  state: () => {
    return {
      // 上传的file信息
      uploadImagesFileList: [],
      // imagedata 和 img 信息
      uploadImagesInfo: [],
      imageFileList: saveImagesFileList
    }
  }
})

