import { defineStore } from "pinia";
import { checkResource, deductResource } from '@/api/userInfo/index.js'
import showNoCreditModal from '@/components/boolv-ui/custom-modal/no-credit.js'
export const useCheckResourceStore = defineStore("resource", {
  state: () => {
    return {

    };
  },

  actions: {

    checkCurrentResource (typeName, isBatch, onCancel) {
      // return new Promise((resolve, reject) => {
      //   checkResource({
      //     "aiType": typeName,
      //     "deductOperType": isBatch ? "BATCH" : "ONCE"
      //   }).then(res => {
      //     const { code, data } = res
      //     const { cause, enough } = data

      //     if (enough) {
      //       resolve()
      //     }

      //     if (!enough && cause === 'NOT_ENOUGH') {
      //       showNoCreditModal({
      //         title: "Insufficient credit",
      //         content: "Looks like you don't have enough credits to use this service. Upgrade to get more credits. ",
      //         okText: "Upgrade now",
      //         onCancel
      //       })
      //     } else if (!enough && cause === 'REMAIN_IS_ZERO') {
      //       showNoCreditModal({
      //         title: "Insufficient credit",
      //         content: "Looks like you don't have enough credits to use this service. Upgrade to get more credits. ",
      //         okText: "Upgrade now",
      //         onCancel
      //       })
      //     }

      //     if (code !== 0 || !data || !enough) {
      //       reject()
      //     }
      //   }).catch(() => {
      //     reject()
      //   })
      // })
    },

    deductResourceFn (typeName, isBatch, num) {
      return new Promise((resolve, reject) => {
        deductResource({
          "aiType": typeName,
          "deductOperType": isBatch ? "BATCH" : "ONCE",
          "num": num
        }).then(() => {
          resolve()
        }).catch(() => {
          reject()
        })
      })

    }
  }

});
