import { defineStore } from "pinia";
import { getSubscriptionList, userSubscribe } from "@/api/premium/index";

const SUBSCRIPTION_CYCLE_ENUM = {
  ALL: {
    code: '0',
    cycleName: '',
  },
  YEAR: {
    code: '1',
    cycleName: 'YEAR',
  },
  MONTH: {
    code: '3',
    cycleName: 'MONTH',
  },
};

export const usePremiumStore = defineStore("premium", {
  // other options...
  state: () => {
    return {
      isUpdateClicked: false,
      isCancelClicked: false,
      isContactClicked: false,
      isPollingClicked: false,
      isRefundClicked: false,
      isUnpaidClick: false,
      isAppsumoSubscribedClick: false,
      unpaidExpireTime: 0,
      allSubscription: [],
      subscriptionList: [],
      currentSelectedOrder: 0,
    };
  },

  getters: {
    getSubscriptionInfo: (state) => {
      return packageCode => state.allSubscription.find(item => item.packageCode === packageCode);
    },
  },
  actions: {
    async fetchSubscriptionList(subscriptionCycle='ALL') {
      if (this.allSubscription.length === 0) {
        const res = await getSubscriptionList({ subscriptionCycle: '0' });
        this.allSubscription.push(...res.data);
      }

      if (subscriptionCycle === 'ALL') {
        return this.allSubscription;
      } else {
        const cycleName = SUBSCRIPTION_CYCLE_ENUM[subscriptionCycle].cycleName;
        return this.subscriptionList = this.allSubscription.filter((item) => item.subscriptionCycle === cycleName);
      }
    },
    async postSubscription (plan) {
      try {
        await userSubscribe(plan);
      } catch (error) {
        console.log(error);
      }
    },
  },
});
