import { _get, _post, _put, _singleGet } from "@/utils/request.js";
const preApi = process.env.NODE_ENV !== "production" ? "/api" : process.env.VUE_APP_API;

const URL = {
  unsubscribe: preApi + "/unsubscribe", // 取消订阅
  subscriptionList: preApi + "/v2/sub_plan/list", // 获取套餐文案列表
  subscribe: preApi + "/subscribe", // 点击订阅套餐
  orderState: preApi + "/subscribe/order-state", // 支付成功与否信息
  operType: preApi + "/v2/subscription/oper_type", //查看操作类型
  refund: preApi + "/apply-refund",// 退款
  checkPromotionCode: preApi + "/v2/coupon/code/check", // 查询优惠码
};

// 取消订阅
const cancelSubscription = async (data) => _put(URL.unsubscribe, data);
// 获取套餐文案列表
const getSubscriptionList = async (data) => _get(URL.subscriptionList, data);
// 订阅套餐
const userSubscribe = async (data) => _post(URL.subscribe, data);
// 支付成功与否信息
const getOrderState = async (data) => _singleGet(URL.orderState, data);
//查看操作类型
const getOperType = async (data) => _get(URL.operType, data);
//退款
const userRefund = async (data) => _post(URL.refund, data);
/** 校验优惠码 */
const checkPromotionCode = async (data) => _post(URL.checkPromotionCode, data);

export {
  cancelSubscription,
  getSubscriptionList,
  userSubscribe,
  getOrderState,
  getOperType,
  userRefund,
  checkPromotionCode,
};
