import { defineStore } from "pinia";
import { safeDeepAssign } from "@/utils/util";

let isScaled = false;
const DEFAULT_VIEWPORT_WIDTH = '390px';
const viewportMeta = document.querySelector('meta[name=viewport]');

function toUnitValue (str) {
  const match = str.match(/^(\d*\.\d+|\d+)([a-z]+)$/);
  return new CSSUnitValue(+match[1], match[2]);
}

function setViewportWidth (width, isForce=false) {
  let deviceWidth = 'device-width';
  let scaleValue = 1;

  
  try {
    const unitValue = toUnitValue(width).to('px');
    const actualViewportWidth = visualViewport.width * visualViewport.scale;
    const flag = actualViewportWidth <= unitValue.value;
    const temp = isScaled;
    
    isScaled = flag;
    
    if (!isForce && flag === temp) {
      return;
    }
    
    if (flag) {
      deviceWidth = unitValue.toString();
      scaleValue = actualViewportWidth / unitValue.value;
    }
  } catch (e) {}
  
  viewportMeta.setAttribute('content', `width=${deviceWidth},initial-scale=${scaleValue.toFixed(5)}`);
}

function handleWindowResize () {
  const viewStore = useViewStore();

  setViewportWidth(viewStore.minViewWidth);
}

window.addEventListener('resize', handleWindowResize);

export const useViewStore = defineStore("view", {
  state: () => {
    return {
      minViewWidth: DEFAULT_VIEWPORT_WIDTH,
      header: {
        disabled: false,
        theme: 'normal',
      }
    };
  },

  actions: {
    reset() {
      this.$reset();
    },
    changeState({ minViewWidth='', ...otherState }) {
      this.reset();
      
      if (minViewWidth !== '') {
        this.setMinViewWidth(minViewWidth);
      }

      safeDeepAssign(this, otherState);
    },
    setMinViewWidth (width) {
      setViewportWidth(width, true);
      this.minViewWidth = width;
    },
  }
});
