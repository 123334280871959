import { reactive } from 'vue';

class Modal {
  constructor() {
    this.isShow = false;
    this.title = '';
    this.content = '';
    this.showClose = true;
    this.icon = null;
    this.footer = null;
    this.customModal = null;
    this.handleClose = () => this.isShow = false;
    
    return reactive(this);
  }
}

class ModalManager {
  constructor(modal) {
    this.modal = modal;
    this.templates = {};
  }

  registerTemplate (name, template) {
    if (typeof template !== 'function') {
      return false;
    }

    this.templates[name] = template.bind(this.modal);
    return true;
  }

  hasTemplate (name) {
    return name in this.templates;
  }

  applyTemplate (name, ...args) {
    if (this.hasTemplate(name)) {
      this.templates[name](...args);
      return true;
    }

    return false;
  }

  deleteTemplate (name) {
    return delete this.templates[name];
  }
}

const modal = new Modal();
const modalManager = new ModalManager(modal);

export default modal;
export { modalManager };
