/*
  bg_remover: 抠图
  eraser: 消除笔
  enhancer: 图像高清化
  blur: 背景模糊
  filter: 滤镜
  resizer：剪裁
  compressor：压缩
  animation：动态特效
  video_enhancer: 视频高清
  paraphraser：博文翻写
  storytelling: 看图说垃圾话
  suggar_comment: 夸夸
*/
import Compressor from "compressorjs";
import { useSubscriptionInfo } from "@/store/user.js";
import axios from "axios";
import md5 from "md5";
import { buryPointReport, fileUploadPre, uploadFile } from "@/api/track/index.js";
import { getSessionsSource, getDeviceType } from "./index.js";
import { isLogin, getEmail } from "./auth";

const subscriptionInfoList = {
  "unknow": {
    isSubscribed: false,
    source: 'undefined',
    planName: 'undefined',
  },
  "201001": {
    isSubscribed: false,
    source: 'booltool',
    planName: 'free',
  },
  "200701": {
    isSubscribed: false,
    source: 'booltool',
    planName: 'free',
  },
  "201101": {
    isSubscribed: true,
    source: 'booltool',
    planName: 'pro',
  },
  "201102": {
    isSubscribed: true,
    source: 'booltool',
    planName: 'annual_pro',
  },
  "201201": {
    isSubscribed: true,
    source: 'booltool',
    planName: 'ultra',
  },
  "201202": {
    isSubscribed: true,
    source: 'booltool',
    planName: 'annual_ultra',
  },
  "200801": {
    isSubscribed: true,
    source: 'appsumo',
    planName: 'pro',
  },
  "200901": {
    isSubscribed: true,
    source: 'appsumo',
    planName: 'ultra',
  },
};

//把存储这个步骤封装在promise中，确保能够同步调用
const storeListInSession = (key, value) => {
  return new Promise((resolve) => {
    sessionStorage.setItem(key, value)
    resolve();
  })
}
export function eventTracking (eventName, param) {
  const subscriptionInfoStore = useSubscriptionInfo();
  const timestamp = Date.now();
  const deviceId = localStorage.getItem("browserId");
  const sessionId = sessionStorage.getItem("sessionId");
  const signId = md5(4 + deviceId + timestamp + eventName + "gJUyT9qGK");
  const sourceImage = /^\[/.test(sessionStorage.getItem("sourceImageId")) ? JSON.parse(sessionStorage.getItem("sourceImageId")) : sessionStorage.getItem("sourceImageId");
  const currentImage = /^\[/.test(sessionStorage.getItem("currentImageId")) ? JSON.parse(sessionStorage.getItem("currentImageId")) : sessionStorage.getItem("sourceImageId");
  const subscriptionInfo = subscriptionInfoList[subscriptionInfoStore.packageCode] ?? subscriptionInfoList.unknow;

  if (isNeedSourceImage(eventName) && sourceImage) {
    param = { ...param, ...{ source_image: sourceImage } };
  }

  if (isNeedCurrentImage(eventName) && currentImage) {
    param = { ...param, ...{ current_image: currentImage } };
  }

  const params = {
    app_id: 4,
    app_name: "booltool",
    user_id: getEmail(),
    device_id: deviceId, //当前设备浏览器唯一id
    session_id: sessionId, //当前浏览器页面的sessionid
    timestamp: timestamp, // 当前时间戳，13 位毫秒级
    device_type: getDeviceType(),
    session_source: getSessionsSource(),
    is_login: isLogin.value,
    event: {
      event_name: eventName,
      properties: param,
    },
    sign: signId,
    is_subscribed: subscriptionInfo.isSubscribed,
    subscribed_plan: subscriptionInfo.planName,
    subscrition_source: subscriptionInfo.source,
  };
  buryPointReport({ ...params });
  if (window.Intercom) {
    window.Intercom("trackEvent", eventName, { ...params, ...param });
  }
}
const processList = async (fileList) => {
  let imageIdList = [];
  for (let i = 0; i < fileList.length; i++) {
    await compressorImage(fileList[i]).then(res => {
      imageIdList.push(res);
    });
  }
  return imageIdList;
}
const storeSourceOrCurrentIdList = async (processedList, isSource) => {
  if (isSource) {
    await storeListInSession('sourceImageId', JSON.stringify(processedList));
    await storeListInSession('currentImageId', JSON.stringify([]));
  }
  else {
    await storeListInSession('currentImageId', JSON.stringify(processedList));
  }
}
export const postFileToUrl = async (fileList, isSource) => {
  let processedList = [];
  console.log('fileList---->', fileList)
  if (fileList.length > 0) {
    if (typeof fileList[0] === 'string') {
      processedList = fileList;
    }
    else {

      processedList = await processList(fileList)

    }
    await storeSourceOrCurrentIdList(processedList, isSource);

  }

}
export const postCanvasToUrl = async (domList, isSource) => {
  const convertDomToFile = (dom) => {
    // Convert canvas image to Base64
    const img = dom.toDataURL();
    // Convert Base64 image to binary
    const file = dataURItoBlob(img);
    return file;
  }

  const fileList = Array.from(domList).map(dom =>
    //处理传入的dom
    convertDomToFile(dom)
  )
  console.log(fileList);
  await postFileToUrl(fileList, isSource)

}
function dataURItoBlob (dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}
//压缩图片
function compressorImage (file) {
  console.log('uploadFile------------>', file)
  return new Promise((resolve, reject) => {
    if (!file) {
      return;
    }

    new Compressor(file, {
      quality: 0.4,
      maxWidth: 500,
      maxHeight: 500,
      convertSize: 5000000,
      mimeType: "image/webp",
      convertTypes: ["image/webp"],
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success (result) {
        console.log("[ result ] >", result);
        uploadImage(result)
          .then(res => {
            resolve(res);
          })
      },
      error (err) {
        console.log(err.message);
        reject(err);
      },
    });
  })

}
//上传图片并获取图片id
const uploadImage = async (file) => {
  const timestamp = Date.now();

  const uploadsign = md5(".webp" + timestamp + "gJUyT9qGK");
  const uploadParams = {
    extension: ".webp",
    timestamp: timestamp,
    sign: uploadsign,
  };

  let currentImageId = null
  await fileUploadPre(uploadParams).then((res) => {
    const noNeedAuthorization = true;
    const data = res.data;
    if (res.code == 0) {
      const uploadData = data;
      const { url, fields } = uploadData;
      sessionStorage.setItem('uploadDataUrl', url);
      console.log('uploadData--------->', uploadData)
      const requestParam = {
        key: fields.key,
        AWSAccessKeyId: fields.AWSAccessKeyId,
        policy: fields.policy,
        signature: fields.signature,
        file: file,
      }
      debugger
      uploadFile(url, requestParam, noNeedAuthorization);
      //把返参中的key作为当前image-id
      currentImageId = fields.key;
    }
  });

  return currentImageId;
};
const isNeedSourceImage = (eventName) => {
  return [
    'booltool_page_upload', 'booltool_page_edit', 'booltool_page_feedback', 'booltool_page_download',
  ].includes(eventName);
}
const isNeedCurrentImage = (eventName) => {
  return [
    'booltool_page_download',
  ].includes(eventName);
}
/*
需要source_image
booltool_page_upload
booltool_page_edit
booltool_page_feedback
需要current_image
booltool_page_edit
booltool_page_feedback
*/
