import { modalManager } from "./reactive";
import router from '@/router';
import BoolvButton from '@/components/boolv-ui/boolv-button/index.vue';
import { Spin } from 'ant-design-vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import warnIcon from '@/assets/boolv-ui/warn_icon.svg';

// 注册全局弹框
modalManager.registerTemplate('no_premission', function(options={}) {
  this.isShow = true;
  this.customModal = null;
  this.icon = null;
  this.title = options.title || 'Upgrade to use this feature';
  this.content = options.title || 'Upgrade now to unlock all amazing features and take your experience to the next level!';

  this.handleClose = () => {
    this.isShow = false;
  };

  const handleClick = () => {
    this.isShow = false;
    router.push('/homePremium');
  }

  this.footer = <BoolvButton onclick={handleClick}>Upgrade</BoolvButton>;
});

modalManager.registerTemplate('switch_to_monthly', function() {
  const iconStyle = {
    width: '24px',
    height: '24px',
    padding: '2px',
  };

  this.isShow = true;
  this.customModal = null;
  this.title = 'Switch to Monthly';
  this.icon = <img src={warnIcon} style={iconStyle}/>
  this.content = (
    <>
      You have already subscribed to a yearly plan. You can switch to monthly any time after your annual subscription ends. If you have any questions, feel free to contact us at <span style="color:#A98CFF">support@boolvector.com</span>.
    </>
  );

  this.handleClose = () => {
    this.isShow = false;
  };

  this.footer = <BoolvButton type="outlined" style="padding-inline: 20px" onclick={this.handleClose}>Got it</BoolvButton>;
});

// pro调用次数用完，进入loading状态
modalManager.registerTemplate('limit_upper_limit', function() {
  const spinStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    inset: 0,
  };

  this.isShow = true;
  this.customModal = <Spin style={spinStyle} spinning indicator={<LoadingOutlined style="color: #eee; font-size: 48px"/>}/>;
});
