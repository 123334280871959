import axios from "axios";
import { message as $message } from "ant-design-vue";
import router from "@/router/index";
import { getToken } from "@/utils/auth.js";
import { clearToken } from "./auth.js";
import { modalManager } from "@/components/boolv-ui/global-modal/reactive.js";
window.axiosPromiseArr = [];
// 创建axios实例
const httpService = axios.create({
  baseURL: window.location.protocol + "//" + window.location.host, // 默认的baseURL
  timeout: 150000, // 请求超时时间，太短容易canceld
});

// request拦截器
httpService.interceptors.request.use(
  (config) => {
    //axios取消请求配置
    config.cancelToken = new axios.CancelToken((cancel) => {
      window.axiosPromiseArr.push({
        url: config.url,
        cancel,
      });
    });
    // res.setHeader("Cross-Origin-Opener-Policy", "same-origin");
    // res.setHeader("Cross-Origin-Embedder-Policy", "require-corp");
    config.headers["Cross-Origin-Opener-Policy"] = "same-origin";
    config.headers["Cross-Origin-Embedder-Policy"] = "require-corp";
    // 让每个请求携带token
    const token = getToken();
    const uploadDataUrl = sessionStorage.getItem('uploadDataUrl');
    // 是否是上报埋点存到s3，如果上报到s3的话不能带有token，会报错导致不能够上传
    const isUploadData = config.url.startsWith(uploadDataUrl);
    if (token && !isUploadData) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

httpService.interceptors.response.use(
  ({ data }) => {
    let result = {
      success: true,
      ...data,
    };

    // 鉴权失败，跳转登录页面, -1 数据类型转换出错
    if (data.code == 401 || data.code == 403) {
      clearToken();
      //判断当前所处的页面，home页面不跳转
      if (router.currentRoute.value.meta.requiresAuth) {
        $message.error("Login expired, please log in again");
        router.push("/login");
      }
    } else if (data.code != 0) {
      if (data.code === 101003 || data.code === 101005) {
        //资源扣减失败
        modalManager.applyTemplate('no_premission');
      } else if (data.code === 101007) {
        //资源扣减失败
        modalManager.applyTemplate('limit_upper_limit');
      }

      //成功状态code为0
      result.success = false;
      result.msg = data.msg;
      return Promise.reject(result);
    } else {
      return Promise.resolve(result);
    }
    return Promise.resolve(result);
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "未授权，请重新登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `未知错误, code: ${error.response.status}`;
      }
    } else {
      error.message = "连接到服务器失败";
    }
    let result = {
      success: false,
      code: -1,
      message: error.msg,
    };

    return Promise.reject(result);
  }
);

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function _get (url, params = {}, config) {
  const requestParams = {
    url: url,
    method: "get",
    params: params,
    ...config,
  };

  return new Promise((resolve, reject) => {
    httpService(requestParams)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function _singleGet (url, data, config) {
  return new Promise((resolve, reject) => {
    httpService({
      url: `${url}/${data}`,
      method: "get",
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function _post (url, params = {}, config) {
  const requestParams = {
    url: url,
    method: "post",
    data: params,
    headers: { "Content-Type": "application/json" },
    ...config,
  };

  // if (cancelToken) {
  //   requestParams.cancelToken = source.token
  // }

  return new Promise((resolve, reject) => {
    httpService(requestParams)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  put请求
 *  url:请求地址
 *  params:参数
 * */
export function _put (url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "put",
      data: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  delete请求
 *  url:请求地址
 *  params:参数
 * */
export function _delete (url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "delete",
      data: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload (url, params = {}) {
  return new Promise((resolve, reject) => {
    let fd = new FormData();
    for (let key in params) {
      fd.append(key, params[key]);
    }
    httpService({
      url: url,
      method: "post",
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error, url);
        reject(error);
      });
  });
}
